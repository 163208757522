.smallIcon {
  width: 11%;
}

.mediumIcon {
  width: 27%;
}

.largeIcon {
  width: 37%;
}
