.apexcharts-gridline {
  display: none !important;
}

.not-active {
  margin: 0 5px;
  font-size: 14px;
  color: gray;
  padding: 5px;
  cursor: pointer;
}

.areaChartPrice {
  font-size: 35px;
  color: #4a4a4a;
  padding: 10px;
  margin-left: 12px;
}
.areaChartPercent {
  font-size: 25px;
  color: #4a4a4a;
  padding: 5px;
  margin-left: 5px;
}

.chartToolBar {
  text-align: right;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.active {
  padding: 5px;
  margin: 0 5px;
  font-size: 14px;
  color: #6433ff;
  cursor: pointer;
  background: #ffffff;
  cursor: pointer;
}
.active {
  color: #6433ff;
  border-top: 2px solid #6433ff;
  background: white;
  border-bottom: none;
}
.fade {
  color: #4a4a4a70;
}
.default {
  color: #6433ff;
  border-top: 2px solid #6433ff;
  background: white;
  border-bottom: none;
}

.areaChartHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
